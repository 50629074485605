import React, { useState, useEffect, useContext, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql, navigate } from 'gatsby'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useQueryParam, StringParam } from 'use-query-params'
import copy from 'copy-to-clipboard'
import { toast } from 'react-toastify'
import { Collapse } from 'react-collapse'
import { FiChevronDown, FiChevronRight, FiCopy } from 'react-icons/fi'

import UserContext from 'context/user/UserContext'
import IndexLayout from 'components/IndexLayout'
import Layout from 'components/layout'
import SEO from 'components/seo'
import Navbar from 'components/navbar'
import LottieShape from 'components/animations/LottieShape'
import LoadingAnimation from 'components/animations/LoadingAnimation'
import SignInUI from 'components/SignInUI'
import Checkbox from 'components/common/Checkbox'
import Button from 'components/button'
import {
  GET_CUSTOM_MEETING_INVITE_LINK,
  GET_MEETING,
  UPDATE_MEETING,
} from 'services/graphql'
import {
  Header,
  LoadingContainer,
  SubHeader,
  Divider,
  ContentContainer,
  FlexRow,
  ColumnRole,
  ColumnDescription,
  ColumnLink,
  UserContentContainerWrapper,
  UserLeftContentContainer,
  UserRightContentContainer,
  HostButtonWrapper,
  LinkShareText,
  RoleName,
  SubmitButtonContainer,
  HoverContainer,
  SaveButtonContainer,
} from 'components/Meeting/CustomMeetingComponents'
import ContentManagerContainer from 'components/ContentManager/ContentManagerContainer'

const CustomMeeting = ({ data, location }) => {
  const PERMISSION_END_MEETING = 'meetingEnd'
  const PERMISSION_START_MEETING = 'meetingStart'
  const PERMISSION_RECALL = 'locationRecall'
  const PERMISSION_MUTE_VOICE = 'voiceMuteOther'
  const PERMISSION_UNMUTE_ONESELF =
    'voiceUnmuteNewProducer videoUnmuteNewProducer'
  const PERMISSION_MUTE_VIDEO = 'videoMuteOther'
  const PERMISSION_LOCATION_BASED_VOICE =
    'videoChangePositionalMode voiceChangePositionalMode'
  const PERMISSION_MUTE_CHAT = 'chatMuteOther'
  const PERMISSION_CONTROL_VIEWBOARDS = 'viewboardControl'

  const ASSISTANT = 'ASSISTANT'
  const PARTICIPANT = 'PARTICIPANT'

  const { t } = useTranslation('customMeeting')
  const { user, isAuthInitialized } = useContext(UserContext)
  const [isNavbarOpen, setNavbarOpen] = useState(false)
  const [isAssistantPermissionsOpen, setIsAssistantPermissionsOpen] = useState(
    false,
  )
  const [
    isParticipantPermissionsOpen,
    setIsParticipantPermissionsOpen,
  ] = useState(false)
  const [
    selectedParticipantPermissions,
    setSelectedParticipantPermissions,
  ] = useState({})
  const [
    selectedAssistantPermissions,
    setSelectedAssistantPermissions,
  ] = useState({
    [PERMISSION_MUTE_VOICE]: true,
    [PERMISSION_MUTE_VIDEO]: true,
    [PERMISSION_MUTE_CHAT]: true,
    [PERMISSION_RECALL]: true,
    [PERMISSION_UNMUTE_ONESELF]: true,
  })
  const [hostLink, setHostLink] = useState('')
  const [assistantLink, setAssistantLink] = useState('')
  const [participantLink, setParticipantLink] = useState('')
  const [selectedUserRole, setSelectedUserRole] = useState('')
  const [
    hasFetchedDynamicLinkOnLoad,
    setHasFetchedDynamicLinkOnLoad,
  ] = useState(false)
  const [initialMeetingContent, setInitialMeetingContent] = useState([])
  const [meetingContent, setMeetingContent] = useState([])
  const [meetingId] = useQueryParam('meeting', StringParam)

  const [
    getMeeting,
    {
      data: getMeetingData,
      loading: getMeetingLoading,
      error: getMeetingError,
    },
  ] = useLazyQuery(GET_MEETING)
  const [
    getCustomMeetingDynamicLink,
    {
      data: getCustomMeetingDynamicLinkData,
      loading: getCustomMeetingDynamicLinkLoading,
      error: getCustomMeetingDynamicLinkError,
    },
  ] = useLazyQuery(GET_CUSTOM_MEETING_INVITE_LINK, {
    fetchPolicy: 'network-only', // force a network call instead of using cache policy
  })

  const [
    updateMeeting,
    {
      data: updateMeetingData,
      loading: updateMeetingLoading,
      error: updateMeetingError,
    },
  ] = useMutation(UPDATE_MEETING)

  const siteTitle = data.site.siteMetadata.title
  const isMeetingValid =
    user &&
    isAuthInitialized &&
    typeof meetingId !== 'undefined' &&
    meetingId &&
    getMeetingData &&
    getMeetingData.getMeeting &&
    getMeetingData.getMeeting.id
  const isUserHostOfMeeting =
    user &&
    getMeetingData &&
    getMeetingData.getMeeting &&
    getMeetingData.getMeeting.hostId === user.uid
  const showUnauthorizedUserFragment = isAuthInitialized && !user
  const showLoading = !isAuthInitialized || !getMeetingData || getMeetingLoading
  const showErrorFragment = !isMeetingValid || !isUserHostOfMeeting
  const ErrorFragment = <h1>{t('genericError')}</h1>
  const UnauthorizedUserFragment = (
    <Layout location={location} title={siteTitle}>
      <SignInUI heading={t('unauthorizedHeading')} signInSuccessUrl="" />
    </Layout>
  )
  const ERROR_MESSAGE = t('shortGenericError')

  const notifyCopiedLink = (msg) => toast.dark(msg)

  const notifyError = () => toast.error(ERROR_MESSAGE)

  const notifySuccess = (message) => toast.success(message)

  // check if the meeting exists on page load
  useEffect(() => {
    const fetchMeeting = async () => {
      await getMeeting({
        variables: { meetingId },
      })
    }

    if (user && user.uid) {
      fetchMeeting()
    }
  }, [user, meetingId, getMeeting])

  useEffect(() => {
    if (getMeetingData && getMeetingData.getMeeting) {
      if (
        getMeetingData.getMeeting.config &&
        getMeetingData.getMeeting.config.content
      ) {
        setInitialMeetingContent(getMeetingData.getMeeting.config.content)
      }
    }
  }, [getMeetingData])

  useEffect(() => {
    if (updateMeetingData && updateMeetingData.updateMeeting) {
      if (
        updateMeetingData.updateMeeting.config &&
        updateMeetingData.updateMeeting.config.content
      ) {
        setInitialMeetingContent(updateMeetingData.updateMeeting.config.content)
      }

      notifySuccess(t('saveMeetingSuccess'))
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [updateMeetingData])

  // set the host link and get the custom meeting dynamic links for the assistant/participant on initial page load
  useEffect(() => {
    const fetchCustomMeetingDynamicLink = async () => {
      await getCustomMeetingDynamicLink({
        variables: {
          aud: meetingId,
          permissions: '',
        },
      })
    }

    // set host link
    setHostLink(
      process.env.GATSBY_SITE_BASE_URL +
        process.env.GATSBY_SITE_INVITE_PATH +
        meetingId,
    )

    if (
      user &&
      isMeetingValid &&
      !getCustomMeetingDynamicLinkLoading &&
      !hasFetchedDynamicLinkOnLoad
    ) {
      setHasFetchedDynamicLinkOnLoad(true)
      fetchCustomMeetingDynamicLink()
    }
  }, [
    isMeetingValid,
    meetingId,
    user,
    getCustomMeetingDynamicLink,
    getCustomMeetingDynamicLinkLoading,
    hasFetchedDynamicLinkOnLoad,
  ])

  // set links after calling dynamic link query
  useEffect(() => {
    if (
      getCustomMeetingDynamicLinkData &&
      getCustomMeetingDynamicLinkData.customMeetingInviteLink &&
      selectedUserRole === ASSISTANT
    ) {
      setAssistantLink(getCustomMeetingDynamicLinkData.customMeetingInviteLink)
    } else if (
      getCustomMeetingDynamicLinkData &&
      getCustomMeetingDynamicLinkData.customMeetingInviteLink &&
      selectedUserRole === PARTICIPANT
    ) {
      setParticipantLink(
        getCustomMeetingDynamicLinkData.customMeetingInviteLink,
      )
    } else if (
      getCustomMeetingDynamicLinkData &&
      getCustomMeetingDynamicLinkData.customMeetingInviteLink
    ) {
      setAssistantLink(getCustomMeetingDynamicLinkData.customMeetingInviteLink)
      setParticipantLink(
        getCustomMeetingDynamicLinkData.customMeetingInviteLink,
      )
    }
  }, [getCustomMeetingDynamicLinkData, selectedUserRole])

  useEffect(() => {
    if (
      getCustomMeetingDynamicLinkError ||
      getMeetingError ||
      updateMeetingError
    ) {
      notifyError()
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [getCustomMeetingDynamicLinkError, getMeetingError, updateMeetingError])

  // get the updated dynamic link after button clicked
  const onClickRolePermissions = async (role) => {
    let meetingPermissions = ''

    if (role === ASSISTANT) {
      meetingPermissions = createPermissionsString(selectedAssistantPermissions)
    } else if (role === PARTICIPANT) {
      meetingPermissions = createPermissionsString(
        selectedParticipantPermissions,
      )
    }

    if (!getCustomMeetingDynamicLinkLoading) {
      await getCustomMeetingDynamicLink({
        variables: {
          aud: meetingId,
          permissions: meetingPermissions,
        },
      })
    }
  }

  const copyHostLink = () => {
    copy(hostLink)
    notifyCopiedLink(t('linkCopiedHost'))
  }

  const copyAssistantLink = () => {
    copy(assistantLink)
    notifyCopiedLink(t('linkCopiedAssistant'))
  }

  const copyParticipantLink = () => {
    copy(participantLink)
    notifyCopiedLink(t('linkCopiedParticipant'))
  }

  const handleCheckboxChangeAssistant = (e) => {
    setSelectedAssistantPermissions({
      ...selectedAssistantPermissions,
      [e.target.name]: e.target.checked,
    })
  }

  const handleCheckboxChangeParticipant = (e) => {
    setSelectedParticipantPermissions({
      ...selectedParticipantPermissions,
      [e.target.name]: e.target.checked,
    })
  }

  // returns space delimited permissions string
  const createPermissionsString = (checkboxPermissionsObject) => {
    const keysArr = Object.keys(checkboxPermissionsObject).filter(
      (item) => checkboxPermissionsObject[item],
    )
    return keysArr.join(' ')
  }

  const startMeeting = async () => {
    if (typeof window !== 'undefined') {
      if (window.onbeforeunload) {
        if (window.confirm(t('confirmSaveBeforeStarting'))) {
          await saveMeeting()
        } else {
          window.onbeforeunload = null
        }
      }
    }
    navigate(process.env.GATSBY_SITE_INVITE_PATH + meetingId)
  }

  const saveMeetingLinkForLater = async () => {
    await saveMeeting()
    copyHostLink()
  }

  const HostActionsFragment = () => (
    <HostButtonWrapper>
      <Button disabled={hostLink === ''} onClick={saveMeetingLinkForLater}>
        {t('saveForLater')}
      </Button>
      <Button onClick={startMeeting}>{t('startNow')}</Button>
    </HostButtonWrapper>
  )

  const preventUnload = (event) => {
    if (typeof window !== 'undefined') {
      const UNLOAD_MESSAGE = t('unloadMessage')
      const e = event || window.event
      if (e) {
        e.returnValue = UNLOAD_MESSAGE
      }
      return UNLOAD_MESSAGE
    }
  }

  const hasContentChanged = (originalContent, updatedContent) => {
    if (originalContent.length !== updatedContent.length) {
      return true
    }

    const isContentSame = originalContent.every(
      (originalItem, originalIndex) => {
        return originalItem.id === updatedContent[originalIndex].id
      },
    )

    return !isContentSame
  }

  const attachedContentChanged = useCallback(
    (attachedContent) => {
      if (hasContentChanged(meetingContent, attachedContent)) {
        setMeetingContent(attachedContent)

        if (typeof window !== 'undefined') {
          window.onbeforeunload = preventUnload
        }
      }
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [setMeetingContent, meetingContent],
  )

  const saveMeeting = useCallback(async () => {
    if (!updateMeetingLoading) {
      await updateMeeting({
        variables: { meetingId: meetingId, content: meetingContent },
      })

      if (typeof window !== 'undefined') {
        window.onbeforeunload = null
      }
    }
  }, [updateMeeting, meetingContent, meetingId, updateMeetingLoading])

  const ASSISTANT_PERMISSIONS = [
    PERMISSION_START_MEETING,
    PERMISSION_CONTROL_VIEWBOARDS,
    PERMISSION_RECALL,
    PERMISSION_LOCATION_BASED_VOICE,
    PERMISSION_UNMUTE_ONESELF,
    PERMISSION_MUTE_VOICE,
    PERMISSION_MUTE_CHAT,
    PERMISSION_MUTE_VIDEO,
    PERMISSION_END_MEETING,
  ]

  const PARTICIPANT_PERMISSIONS = [
    PERMISSION_CONTROL_VIEWBOARDS,
    PERMISSION_RECALL,
    PERMISSION_LOCATION_BASED_VOICE,
    PERMISSION_UNMUTE_ONESELF,
  ]

  return (
    <IndexLayout>
      <SEO title={t('title')} />
      {showLoading && !showUnauthorizedUserFragment ? (
        <LoadingContainer>
          <LottieShape animationData={LoadingAnimation} />
        </LoadingContainer>
      ) : null}
      {showUnauthorizedUserFragment ? UnauthorizedUserFragment : null}
      {showErrorFragment && !showLoading ? ErrorFragment : null}
      {!showLoading && !showUnauthorizedUserFragment && !showErrorFragment && (
        <>
          <Navbar isNavbarOpen={isNavbarOpen} setNavbarOpen={setNavbarOpen} />
          <Header>{t('heading')}</Header>
          {HostActionsFragment()}
          <SaveButtonContainer>
            <Button disabled={updateMeetingLoading} onClick={saveMeeting}>
              {updateMeetingLoading ? (
                <LottieShape animationData={LoadingAnimation} size={48} />
              ) : (
                t('saveChanges')
              )}
            </Button>
          </SaveButtonContainer>
          <ContentContainer>
            <SubHeader>{t('headingPrivileges')}</SubHeader>

            <UserContentContainerWrapper>
              <UserLeftContentContainer>
                <FlexRow>
                  <ColumnRole>{t('roleNameHeading')}</ColumnRole>
                  <ColumnDescription>
                    {t('roleDescriptionHeading')}
                  </ColumnDescription>
                </FlexRow>
              </UserLeftContentContainer>
              <UserRightContentContainer>
                <ColumnLink>{t('roleLinkCopy')}</ColumnLink>
              </UserRightContentContainer>
            </UserContentContainerWrapper>

            <Divider />

            <UserContentContainerWrapper>
              <UserLeftContentContainer>
                <FlexRow>
                  <ColumnRole>
                    <HoverContainer
                      onClick={() =>
                        setIsAssistantPermissionsOpen(
                          !isAssistantPermissionsOpen,
                        )
                      }
                    >
                      {isAssistantPermissionsOpen ? (
                        <FiChevronDown
                          size="1.4em"
                          style={{ marginRight: '.5em', color: 'black' }}
                        />
                      ) : (
                        <FiChevronRight
                          size="1.4em"
                          style={{ marginRight: '.5em', color: 'black' }}
                        />
                      )}
                      <RoleName>{t('roleNameAssistant')}</RoleName>
                    </HoverContainer>
                  </ColumnRole>
                  <ColumnDescription>
                    {t('roleDescriptionAssistant')}
                  </ColumnDescription>
                </FlexRow>

                <Collapse isOpened={isAssistantPermissionsOpen}>
                  {ASSISTANT_PERMISSIONS.map((permission) => {
                    return (
                      <FlexRow key={`assistant-${permission}`}>
                        <ColumnRole>
                          <Checkbox
                            name={permission}
                            checked={selectedAssistantPermissions[permission]}
                            onChange={handleCheckboxChangeAssistant}
                          />
                        </ColumnRole>
                        <ColumnDescription>
                          {t(`permissions.${permission}`)}
                        </ColumnDescription>
                      </FlexRow>
                    )
                  })}
                </Collapse>
                {isAssistantPermissionsOpen && (
                  <SubmitButtonContainer>
                    <Button
                      onClick={() => {
                        setSelectedUserRole(ASSISTANT)
                        onClickRolePermissions(ASSISTANT)
                      }}
                    >
                      {t('roleSave')}
                    </Button>
                  </SubmitButtonContainer>
                )}
              </UserLeftContentContainer>
              <UserRightContentContainer>
                <ColumnLink
                  style={{ cursor: 'pointer' }}
                  onClick={() => copyAssistantLink()}
                >
                  {getCustomMeetingDynamicLinkLoading &&
                  selectedUserRole === ASSISTANT ? (
                    <LottieShape animationData={LoadingAnimation} size={80} />
                  ) : (
                    <FiCopy
                      disabled={assistantLink === ''}
                      size="5rem"
                      style={{ color: '#ebbd34' }}
                    />
                  )}
                  <LinkShareText>{t('roleInviteLinkAssistant')}</LinkShareText>
                </ColumnLink>
              </UserRightContentContainer>
            </UserContentContainerWrapper>

            <Divider />

            <UserContentContainerWrapper>
              <UserLeftContentContainer>
                <FlexRow>
                  <ColumnRole>
                    <HoverContainer
                      onClick={() =>
                        setIsParticipantPermissionsOpen(
                          !isParticipantPermissionsOpen,
                        )
                      }
                    >
                      {isParticipantPermissionsOpen ? (
                        <FiChevronDown
                          size="1.4em"
                          style={{ marginRight: '.5em', color: 'black' }}
                        />
                      ) : (
                        <FiChevronRight
                          size="1.4em"
                          style={{ marginRight: '.5em', color: 'black' }}
                        />
                      )}
                      <RoleName>{t('roleNameParticipant')}</RoleName>
                    </HoverContainer>
                  </ColumnRole>
                  <ColumnDescription>
                    {t('roleDescriptionParticipant')}
                  </ColumnDescription>
                </FlexRow>

                <Collapse isOpened={isParticipantPermissionsOpen}>
                  {PARTICIPANT_PERMISSIONS.map((permission) => {
                    return (
                      <FlexRow key={`particpant-${permission}`}>
                        <ColumnRole>
                          <Checkbox
                            name={permission}
                            checked={selectedParticipantPermissions[permission]}
                            onChange={handleCheckboxChangeParticipant}
                          />
                        </ColumnRole>
                        <ColumnDescription>
                          {t(`permissions.${permission}`)}
                        </ColumnDescription>
                      </FlexRow>
                    )
                  })}
                </Collapse>
                {isParticipantPermissionsOpen && (
                  <SubmitButtonContainer>
                    <Button
                      onClick={() => {
                        setSelectedUserRole(PARTICIPANT)
                        onClickRolePermissions(PARTICIPANT)
                      }}
                    >
                      {t('roleSave')}
                    </Button>
                  </SubmitButtonContainer>
                )}
              </UserLeftContentContainer>
              <UserRightContentContainer>
                <ColumnLink
                  style={{ cursor: 'pointer' }}
                  onClick={() => copyParticipantLink()}
                >
                  {getCustomMeetingDynamicLinkLoading &&
                  selectedUserRole === PARTICIPANT ? (
                    <LottieShape animationData={LoadingAnimation} size={80} />
                  ) : (
                    <FiCopy
                      disabled={participantLink === ''}
                      size="5rem"
                      style={{ color: '#ebbd34' }}
                    />
                  )}
                  <LinkShareText>
                    {t('roleInviteLinkParticipant')}
                  </LinkShareText>
                </ColumnLink>
              </UserRightContentContainer>
            </UserContentContainerWrapper>
          </ContentContainer>
          <ContentContainer>
            <SubHeader>{t('headingContent')}</SubHeader>

            <UserContentContainerWrapper>
              <ContentManagerContainer
                onAttachChanged={attachedContentChanged}
                initialAttachedContent={initialMeetingContent}
              />
            </UserContentContainerWrapper>
          </ContentContainer>
          <SaveButtonContainer>
            <Button disabled={updateMeetingLoading} onClick={saveMeeting}>
              {updateMeetingLoading ? (
                <LottieShape animationData={LoadingAnimation} size={48} />
              ) : (
                t('saveChanges')
              )}
            </Button>
          </SaveButtonContainer>
          {HostActionsFragment()}
        </>
      )}
    </IndexLayout>
  )
}

export default CustomMeeting

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
