import styled from '@emotion/styled'
import mq from 'styles/breakpoints'

export const Header = styled.h1`
  padding-top: 3rem;
  font-weight: bold;
  font-family: arial;
  text-align: center;

  ${mq['sm']} {
    font-size: 2rem;
  }
`

export const SubHeader = styled.h2`
  font-weight: normal;
  font-family: arial;

  ${mq['sm']} {
    text-align: center;
    font-size: 1.4rem;
  }
`

export const Divider = styled.hr`
  height: 2px;
  width: 100%;
  background-color: #f0f0f0;
  margin: 0.5rem 2rem;

  ${mq['xl']} {
    margin: 0.5rem 0;
  }
`

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 70%;
  font-family: arial;
  font-size: 1.125rem;
  margin-bottom: 4rem;

  ${mq['xl']} {
    width: 90%;
  }

  ${mq['md']} {
    width: 95%;
  }

  ${mq['sm']} {
    width: 99%;
    font-size: 0.6rem;
  }
`

export const SaveButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const FlexRow = styled.div`
  display: flex;
  color: gray;
`

export const ColumnRole = styled.div`
  width: 18%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2rem;

  input[type='checkbox'] {
    height: 17px;
    width: 17px;
  }

  ${mq['xl']} {
    width: 22%;
  }

  ${mq['md']} {
    width: 28%;
  }

  ${mq['sm']} {
    margin-right: 1rem;
    width: 26%;

    input[type='checkbox'] {
      height: 15px;
      width: 15px;
    }
  }
`
export const ColumnDescription = styled.div`
  width: 100%;
`
export const ColumnLink = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const UserContentContainerWrapper = styled.div`
  display: flex;
`

export const UserLeftContentContainer = styled.div`
  width: 80%;

  ${mq['sm']} {
    width: 70%;
  }
`
export const HostButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;

  button:nth-of-type(1) {
    margin-right: 1rem;
  }

  ${mq['md']} {
    flex-direction: column;

    button:nth-of-type(1) {
      margin-right: 0;
      margin-bottom: 1.5rem;
    }
  }
`

export const UserRightContentContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 20%;

  ${mq['sm']} {
    width: 30%;
  }
`

export const LinkShareText = styled.p`
  color: gray;
  text-align: center;
  margin-bottom: 0;
`

export const RoleName = styled.p`
  font-weight: bold;
  color: black;
  margin-bottom: 1rem;
`

export const SubmitButtonContainer = styled.div`
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  display: flex;
  justify-content: center;
  ${mq['sm']} {
    margin-top: 0.75rem;

    button {
      font-size: 10px;
    }
  }
`

export const HoverContainer = styled.div`
  display: flex;
  width: 100%;
  user-select: none;

  &:hover {
    cursor: pointer;
  }
`
