import React, { useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import axios from 'axios'
import { useLazyQuery, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'

import AttachedContent from './AttachedContent'
import ContentLibrary from './ContentLibrary'
import {
  ErrorMessage,
  ErrorMessageBlock,
  ErrorMessages,
  ManagerContainer,
  SuccessMessageBlock,
} from './ContentManagerComponents'
import UploadContent from './UploadContent'
import {
  ADD_CONTENT,
  DELETE_CONTENT,
  DELETE_COLLECTION,
  GET_LIBRARY,
} from 'services/graphql'

const LIBRARY_ACTIONS = Object.freeze({
  DELETE: 'DELETE',
  ATTACH: 'ATTACH',
})

const MIME_TYPE_MAPPING = Object.freeze({
  video: ['video/mp4', 'VIDEOMP4'],
  image: ['image/jpeg', 'image/png', 'IMAGEJPEG', 'IMAGEPNG'],
})

// TODO: Pass in functions to handle the GraphQL requests instead of handling them directly here?
const ContentManagerContainer = ({
  initialAttachedContent,
  onAttachChanged,
}) => {
  const { t } = useTranslation('contentManager')
  const [library, setLibrary] = useState({ content: [], collections: [] })
  const [attachedContent, setAttachedContent] = useState([])

  // If needed, we can add loading listeners to the mutations and queries
  const [addContent, { error: addContentError }] = useMutation(ADD_CONTENT)
  const [
    getLibrary,
    {
      loading: getLibraryLoading,
      data: getLibraryData,
      error: getLibraryError,
    },
  ] = useLazyQuery(GET_LIBRARY)
  const [deleteContent, { error: deleteContentError }] = useMutation(
    DELETE_CONTENT,
  )
  const [deleteCollection, { error: deleteCollectionError }] = useMutation(
    DELETE_COLLECTION,
  )

  const generateErrorToast = useCallback((apolloError) => {
    const errorMessages = []
    apolloError.graphQLErrors.forEach((gqlError) => {
      switch (gqlError.extensions.code) {
        case 'BAD_USER_INPUT': {
          const validationMessages = Object.keys(
            gqlError.extensions.validationErrors,
          ).reduce((messages, validationErrorKey) => {
            messages.push(
              <ErrorMessage key={`validation-${validationErrorKey}`}>
                {gqlError.extensions.validationErrors[validationErrorKey]}
              </ErrorMessage>,
            )
            return messages
          }, [])

          errorMessages.push(
            <ErrorMessageBlock key={'errors-validation'}>
              {'Validation errors'}
              <br />
              {validationMessages}
            </ErrorMessageBlock>,
          )
          break
        }

        case 'INTERNAL_SERVER_ERROR': {
          errorMessages.push(
            <ErrorMessageBlock key={'errors-server'}>
              {gqlError.message}
            </ErrorMessageBlock>,
          )
          break
        }

        default: {
          console.warn(
            'Unexpected error extension code: ',
            gqlError.extensions.code,
          )
          return
        }
      }
    })

    return <ErrorMessages>{errorMessages}</ErrorMessages>
  }, [])

  useEffect(() => {
    if (addContentError) {
      toast.error(generateErrorToast(addContentError))
    }

    if (getLibraryError) {
      toast.error(generateErrorToast(getLibraryError))
    }

    if (deleteContentError) {
      toast.error(generateErrorToast(deleteContentError))
    }

    if (deleteCollectionError) {
      toast.error(generateErrorToast(deleteCollectionError))
    }
  }, [
    addContentError,
    getLibraryError,
    deleteContentError,
    deleteCollectionError,
    generateErrorToast,
  ])

  const fetchLibrary = useCallback(() => {
    if (getLibraryLoading) return

    getLibrary()
  }, [getLibraryLoading, getLibrary])

  // Get library and set up attachedContent from IDs
  useEffect(() => {
    fetchLibrary()
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  useEffect(() => {
    const formattedContent = attachedContent.map((item) => {
      const isContent =
        item.__typename === 'GetContentPayload' ||
        item.__typename === 'AddContentPayload'
      const itemType = isContent
        ? convertMimeType(item.content.mimeType)
        : 'collection'
      const itemId = isContent ? item.content.id : item.id
      return {
        id: itemId,
        type: itemType,
      }
    })
    onAttachChanged(formattedContent)
  }, [attachedContent, onAttachChanged])

  const mapContent = useCallback(
    (libraryToMap) => {
      // Map initial content to library content
      const mappedContent = initialAttachedContent.reduce(
        (reducedContentArray, configData) => {
          switch (configData.type) {
            case 'image':
            case 'video': {
              const item = libraryToMap.content.find((contentItem) => {
                return contentItem.content.id === configData.id
              })
              if (item) {
                reducedContentArray.push(item)
              }
              break
            }

            case 'collection': {
              const item = libraryToMap.collections.find((collectionItem) => {
                return collectionItem.content.id === configData.id
              })
              if (item) {
                reducedContentArray.push(item)
              }
              break
            }

            default: {
              console.warn('Unexpected type in attached content, removing')
              break
            }
          }
          return reducedContentArray
        },
        [],
      )

      setAttachedContent(mappedContent)
    },
    [initialAttachedContent, setAttachedContent],
  )

  useEffect(() => {
    mapContent(library)

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [initialAttachedContent])

  useEffect(() => {
    if (getLibraryError || !getLibraryData) {
      return
    }

    // Set library data from query
    const newLibrary = { ...library }
    newLibrary.content = [...getLibraryData.getLibrary.library.content]
    newLibrary.collections = [...getLibraryData.getLibrary.library.collections]
    setLibrary(newLibrary)

    mapContent(newLibrary)
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [getLibraryData, getLibraryError])

  const convertMimeType = (mimeType) => {
    if (MIME_TYPE_MAPPING.video.includes(mimeType)) {
      return 'video'
    }

    if (MIME_TYPE_MAPPING.image.includes(mimeType)) {
      return 'image'
    }

    return null
  }

  const onUpload = (content) => {
    // TODO: Send getLibrary query?
    const newLibrary = { ...library }
    newLibrary.content.push(content)
    setLibrary(newLibrary)

    // Attach immediately in this view
    setAttachedContent((attached) => {
      if (!attached.includes(content)) {
        return [...attached, content]
      }
    })
  }

  const addContentAndUpload = async (
    fileId,
    contentInfo,
    fileInfo,
    callback,
  ) => {
    let mutationResult
    try {
      mutationResult = await addContent({
        variables: { input: contentInfo },
      })
    } catch (err) {
      callback(fileId, err.graphQLErrors, null)
      return
    }

    // Upload file
    try {
      await uploadFileToSignedUrl(
        mutationResult.data.addContent.upload.url,
        fileInfo.hashes.base64,
        fileInfo.file.type,
        fileInfo.file,
      )
    } catch (err) {
      toast.error(
        <ErrorMessageBlock>
          {t('uploadError', { filename: fileId })}
          <br />
          {err.message}
        </ErrorMessageBlock>,
      )
      callback(fileId, err, null)
      return
    }
    toast.success(
      <SuccessMessageBlock>
        {t('uploadSuccess', { filename: fileId })}
      </SuccessMessageBlock>,
    )
    callback(fileId, null, mutationResult.data.addContent)
    onUpload(mutationResult.data.addContent)
  }

  const uploadFileToSignedUrl = (signedUrl, base64Hash, mimeType, file) => {
    const config = {
      headers: {
        'Content-MD5': base64Hash,
        'Content-Type': mimeType,
        'Access-Control-Allow-Origin': '*',
      },
    }
    return axios.put(signedUrl, file, config)
  }

  const handleDetachContent = useCallback(
    (contentToDetach) => {
      const updatedContent = attachedContent.filter(
        (content) => content.content.id !== contentToDetach.content.id,
      )
      setAttachedContent(updatedContent)
    },
    [attachedContent, setAttachedContent],
  )

  const handleLibraryAction = useCallback(
    async (action, content, contentType) => {
      switch (action) {
        case LIBRARY_ACTIONS.ATTACH: {
          if (!attachedContent.includes(content)) {
            setAttachedContent([...attachedContent, content])
          }
          return
        }

        case LIBRARY_ACTIONS.DELETE: {
          const updatedLibrary = {
            ...library,
          }
          let container =
            contentType === 'collection' ? 'collections' : 'content'

          const idToDelete =
            contentType === 'collection' ? content.id : content.content.id
          // TODO: let users delete content with a collection
          const mutationInput = {
            id: idToDelete,
            ...(container === 'collections' && {
              deleteAttachedContentItems: false,
            }),
          }
          const deleteMutation =
            container === 'collections' ? deleteCollection : deleteContent
          try {
            await deleteMutation({
              variables: { input: mutationInput },
            })

            updatedLibrary[container] = updatedLibrary[container].filter(
              (libContent) => {
                const libContentId = libContent.content.id ?? libContent.id
                return libContentId !== idToDelete
              },
            )
            setLibrary(updatedLibrary)
          } catch (err) {
            console.error(err.message)
            console.error(err.graphQLErrors)
          }

          return
        }

        default: {
          console.warn('Unexpected action for removing content', action)
          return
        }
      }
    },
    [
      library,
      setLibrary,
      attachedContent,
      setAttachedContent,
      deleteCollection,
      deleteContent,
    ],
  )

  return (
    <>
      <ManagerContainer>
        <UploadContent onUpload={onUpload} addContent={addContentAndUpload} />
        <AttachedContent
          attached={attachedContent}
          detachContent={handleDetachContent}
        />
        <ContentLibrary
          library={library}
          actionType={LIBRARY_ACTIONS.ATTACH}
          action={handleLibraryAction}
        />
      </ManagerContainer>
    </>
  )
}

ContentManagerContainer.defaultProps = {
  initialAttachedContent: [],
}

ContentManagerContainer.propTypes = {
  onAttachChanged: PropTypes.func.isRequired,
  initialAttachedContent: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['image', 'video', 'collection']),
    }),
  ),
}

export default ContentManagerContainer
