import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { FiFileMinus } from 'react-icons/fi'

import {
  ComponentContainer,
  ComponentSubTitle,
  ComponentTitle,
  CONTENT_ICON_SIZE,
  CONTENT_REMOVE_COLOR,
  ContentItem,
  ContentItemName,
  ContentItemList,
  ComponentBody,
} from './ContentManagerComponents'

const AttachedContent = ({ attached, detachContent }) => {
  const { t } = useTranslation('contentManager')
  const [attachedElements, setAttachedElements] = useState([])

  useEffect(() => {
    const newAttachedElements = attached.map((contentItem) => {
      const content =
        contentItem.__typename === 'NestedCollection'
          ? contentItem
          : contentItem.content
      return (
        <ContentItem key={content.id}>
          <FiFileMinus
            size={CONTENT_ICON_SIZE}
            style={{
              color: CONTENT_REMOVE_COLOR,
              marginRight: '0.75rem',
              cursor: 'pointer',
            }}
            onClick={() => {
              detachContent(contentItem)
            }}
          />
          <ContentItemName>{content.name}</ContentItemName>
        </ContentItem>
      )
    })
    setAttachedElements(newAttachedElements)
  }, [attached, setAttachedElements, detachContent])

  return (
    <ComponentContainer>
      <ComponentTitle>{t('attachContent.heading')}</ComponentTitle>
      <ComponentSubTitle>{t('attachContent.subheading')}</ComponentSubTitle>
      <ComponentBody>
        <ContentItemList>{attachedElements}</ContentItemList>
      </ComponentBody>
    </ComponentContainer>
  )
}

AttachedContent.defaultProps = {
  attached: [],
}

// Attached is an array of content and/or collection types (so two types)
const contentShape = PropTypes.shape({
  content: PropTypes.shape({
    id: PropTypes.string,
    ownerId: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    mimeType: PropTypes.string,
    uploadedAt: PropTypes.number,
    modifiedAt: PropTypes.number,
    size: PropTypes.number,
    hash: PropTypes.string,
    status: PropTypes.string,
  }),
  download: PropTypes.shape({
    url: PropTypes.string,
    expires: PropTypes.number,
  }),
})
AttachedContent.propTypes = {
  attached: PropTypes.arrayOf(
    PropTypes.oneOfType([
      contentShape,
      PropTypes.shape({
        id: PropTypes.string,
        ownerId: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
        modifiedAt: PropTypes.number,
        createdAt: PropTypes.number,
        content: PropTypes.arrayOf(contentShape),
      }),
    ]),
  ),
  detachContent: PropTypes.func.isRequired,
}

export default AttachedContent
