import React, { useCallback, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import { FiAlertCircle, FiHexagon, FiTrash2 } from 'react-icons/fi'
import md5 from 'js-md5'
import { toast } from 'react-toastify'

import {
  CONTENT_ICON_SIZE,
  ActionButton,
  ComponentBody,
  ComponentContainer,
  ComponentSubTitle,
  ComponentTitle,
  ContentItemList,
  ContentItem,
  ContentItemName,
  CONTENT_REMOVE_COLOR,
  CONTENT_ADD_COLOR,
  ErrorMessage,
  ErrorMessages,
} from './ContentManagerComponents'
import humanFileSize from 'utils/humanFileSize'

// TODO: Get this from the content service via a query
const ACCEPTED_MIME_TYPES = ['image/jpeg', 'image/png', 'video/mp4']

// TODO: Get this from user service (user allowance)
const UPLOAD_SIZE_LIMIT =
  parseInt(process.env.GATSBY_MAX_FILE_SIZE) ?? 1024 * 1024 * 100 // 100 MB in bytes

const FileState = Object.freeze({
  SELECTED: 'SELECTED',
  UPLOADING: 'UPLOADING',
  ERROR: 'ERROR',
  UPLOADED: 'UPLOADED',
})

// TODO: Add dragenter/leave events to full page/container, not just upload div?

const UploadContent = ({ addContent }) => {
  const { t } = useTranslation('contentManager')
  const [uploadFiles, setUploadFiles] = useState({})
  const [uploadFileItems, setUploadFileItems] = useState([])

  const calculateMD5HashesForFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = (e) => {
        const hash = md5.create()
        hash.update(e.target.result)
        resolve({
          hex: hash.hex(),
          base64: hash.base64(),
        })
      }

      reader.onerror = reject
      reader.onabort = reject

      reader.readAsArrayBuffer(file)
    })
  }

  const removeFileFromList = useCallback(
    (fileId) => {
      if (!(fileId in uploadFiles)) {
        return
      }

      const temp = {
        ...uploadFiles,
      }

      switch (uploadFiles[fileId].state) {
        case FileState.UPLOADING: {
          // If current state is uploading, we might not want to remove it just yet
          return
        }

        case FileState.ERROR:
        case FileState.UPLOADED:
        case FileState.SELECTED: {
          // Remove file from state
          delete temp[fileId]
          setUploadFiles(temp)
          return
        }

        default: {
          console.warn('Unexpected state', uploadFiles[fileId].state)
          return
        }
      }
    },
    [setUploadFiles, uploadFiles],
  )

  const onDrop = useCallback(
    async (acceptedFiles, fileRejections, event) => {
      if (acceptedFiles) {
        const files = {
          ...uploadFiles,
        }
        for await (const file of Array.from(acceptedFiles)) {
          if (file.name in files) {
            // Don't add the file again if it has been added already
            return
          }

          const hashes = await calculateMD5HashesForFile(file)

          files[file.name] = {
            file,
            input: {
              mimeType: file.type.toUpperCase().replace('/', ''),
              hash: hashes.hex,
              size: file.size,
              name: file.name,
            },
            hashes,
            state: FileState.SELECTED,
          }
        }
        setUploadFiles(files)
      }

      if (fileRejections) {
        fileRejections.forEach((rejection) => {
          const errorMessages = rejection.errors.reduce(
            (messages, rejectionError) => {
              messages.push(
                <ErrorMessage key={rejectionError.code}>
                  {rejectionError.code}
                </ErrorMessage>,
              )
              return messages
            },
            [],
          )
          toast.error(
            <ErrorMessages>
              {rejection.file.name}
              <br />
              {errorMessages}
            </ErrorMessages>,
          )
        })
      }
    },
    [uploadFiles, setUploadFiles],
  )

  const addContentCallback = useCallback(
    (fileId, errors, result) => {
      const updatedFiles = { ...uploadFiles }
      if (errors) {
        updatedFiles[fileId].state = FileState.ERROR
      } else if (result && result.content && result.upload) {
        updatedFiles[fileId].state = FileState.UPLOADED
        setTimeout(() => {
          removeFileFromList(fileId)
        }, 1000)
      }
      setUploadFiles(updatedFiles)
    },
    [uploadFiles, setUploadFiles, removeFileFromList],
  )

  const handleUploadButtonClick = useCallback(() => {
    const addingFiles = { ...uploadFiles }
    Object.keys(uploadFiles).forEach((fileId) => {
      const fileInfo = uploadFiles[fileId]
      if (fileInfo.state === FileState.SELECTED) {
        addingFiles[fileId].state = FileState.UPLOADING
        addContent(
          fileId,
          fileInfo.input,
          { hashes: fileInfo.hashes, file: fileInfo.file },
          addContentCallback,
        )
      }
    })
    setUploadFiles(addingFiles)
  }, [addContent, uploadFiles, setUploadFiles, addContentCallback])

  const getAction = useCallback(
    (file, state) => {
      switch (state) {
        case FileState.SELECTED: {
          return (
            <FiTrash2
              size={CONTENT_ICON_SIZE}
              style={{
                color: CONTENT_REMOVE_COLOR,
                marginRight: '0.75rem',
                cursor: 'pointer',
              }}
              onClick={() => {
                removeFileFromList(file.name)
              }}
            />
          )
        }

        case FileState.UPLOADED:
        case FileState.UPLOADING: {
          return (
            <FiHexagon
              className={'spin'}
              size={CONTENT_ICON_SIZE}
              style={{
                color: CONTENT_ADD_COLOR,
                marginRight: '0.75rem',
              }}
            />
          )
        }

        case FileState.ERROR: {
          return (
            <FiAlertCircle
              size={CONTENT_ICON_SIZE}
              style={{
                color: CONTENT_REMOVE_COLOR,
                marginRight: '0.75rem',
                cursor: 'pointer',
              }}
              onClick={() => {
                removeFileFromList(file.name)
              }}
            />
          )
        }

        default: {
          console.warn('Unexpected file state ', state)
          return
        }
      }
    },
    [removeFileFromList],
  )

  useEffect(() => {
    const newUploadItems = Object.keys(uploadFiles).map((fileId) => {
      const fileInfo = uploadFiles[fileId]
      const file = fileInfo.file
      return (
        <ContentItem
          key={file.name}
          className={fileInfo.state === FileState.UPLOADED ? 'fade-out' : ''}
        >
          {getAction(file, fileInfo.state)}
          <ContentItemName>{file.name}</ContentItemName>
        </ContentItem>
      )
    })
    setUploadFileItems(newUploadItems)
  }, [setUploadFileItems, removeFileFromList, uploadFiles, getAction])

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    accept: ACCEPTED_MIME_TYPES,
    maxSize: UPLOAD_SIZE_LIMIT,
  })

  return (
    <ComponentContainer>
      <ComponentTitle>{t('uploadContent.heading')}</ComponentTitle>
      <ComponentSubTitle>{t('uploadContent.subheading')}</ComponentSubTitle>
      <ComponentBody {...getRootProps({})}>
        <input {...getInputProps({})} />
        <div>{t('uploadContent.dropFiles')}</div>
        <div style={{ marginTop: '1rem' }}>
          {t('uploadContent.supportedFilesWithSize', {
            size: humanFileSize(UPLOAD_SIZE_LIMIT),
          })}
          <div>{t('uploadContent.supportedFileExtensions')}</div>
        </div>
        <ActionButton background={'#ebbd34'} onClick={open}>
          {t('uploadContent.selectFiles')}
        </ActionButton>
        <ContentItemList>{uploadFileItems}</ContentItemList>
      </ComponentBody>
      <ActionButton onClick={handleUploadButtonClick}>
        {t('uploadContent.uploadButton')}
      </ActionButton>
    </ComponentContainer>
  )
}

// Function signature: addContent(fileId: string, contentInfo: AddContentInput, fileInfo: {hashes: {hex: string, base64: string}, file: File}, callback(fileId: string, errors: Error | any[], result: AddContentPayload))
UploadContent.propTypes = {
  addContent: PropTypes.func.isRequired,
}

export default UploadContent
