import React, { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { FiTrash2, FiFilePlus } from 'react-icons/fi'

import {
  CONTENT_ICON_SIZE,
  ComponentBody,
  ComponentContainer,
  ComponentSubTitle,
  ComponentTitle,
  CONTENT_ADD_COLOR,
  CONTENT_REMOVE_COLOR,
  ContentItem,
  ContentItemName,
  ContentItemList,
} from './ContentManagerComponents'

const ContentLibrary = ({ library, actionType, action }) => {
  const { t } = useTranslation('contentManager')
  const [libraryElements, setLibraryElements] = useState([])

  const getAction = useCallback(
    (content, contentType) => {
      switch (actionType) {
        case 'ATTACH': {
          return (
            <FiFilePlus
              size={CONTENT_ICON_SIZE}
              style={{
                color: CONTENT_ADD_COLOR,
                marginRight: '0.75rem',
                cursor: 'pointer',
              }}
              onClick={() => {
                action(actionType, content, contentType)
              }}
            />
          )
        }

        case 'DELETE': {
          return (
            <FiTrash2
              size={CONTENT_ICON_SIZE}
              style={{
                color: CONTENT_REMOVE_COLOR,
                marginRight: '0.75rem',
                cursor: 'pointer',
              }}
              onClick={() => {
                action(actionType, content, contentType)
              }}
            />
          )
        }

        default: {
          console.warn('Unexpected action type for library')
          return
        }
      }
    },
    [action, actionType],
  )

  useEffect(() => {
    const newContentItems = library.content.map((contentItem) => {
      const content = contentItem.content
      return (
        <ContentItem key={content.id}>
          {getAction(contentItem, 'content')}
          <ContentItemName>{content.name}</ContentItemName>
        </ContentItem>
      )
    })
    const newCollectionItems = library.collections.map((collectionItem) => {
      return (
        <ContentItem key={collectionItem.id}>
          {getAction(collectionItem, 'collection')}
          <ContentItemName>{collectionItem.name}</ContentItemName>
        </ContentItem>
      )
    })
    setLibraryElements([...newContentItems, ...newCollectionItems])
  }, [library, setLibraryElements, getAction])

  return (
    <ComponentContainer>
      <ComponentTitle>{t('contentLibrary.heading')}</ComponentTitle>
      <ComponentSubTitle>{t('contentLibrary.subheading')}</ComponentSubTitle>
      <ComponentBody>
        <ContentItemList>{libraryElements}</ContentItemList>
      </ComponentBody>
    </ComponentContainer>
  )
}

ContentLibrary.defaultProps = {
  library: {
    content: [],
    collections: [],
  },
  actionType: 'DELETE',
}

const contentShape = PropTypes.shape({
  content: PropTypes.shape({
    id: PropTypes.string,
    ownerId: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    mimeType: PropTypes.string,
    uploadedAt: PropTypes.number,
    modifiedAt: PropTypes.number,
    size: PropTypes.number,
    hash: PropTypes.string,
    status: PropTypes.string,
  }),
  download: PropTypes.shape({
    url: PropTypes.string,
    expires: PropTypes.number,
  }),
})
ContentLibrary.propTypes = {
  action: PropTypes.func.isRequired,
  actionType: PropTypes.oneOf(['ATTACH', 'DELETE']),
  library: PropTypes.shape({
    content: PropTypes.arrayOf(contentShape),
    collections: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        ownerId: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
        createdAt: PropTypes.number,
        modifiedAt: PropTypes.number,
        content: PropTypes.arrayOf(contentShape),
      }),
    ),
  }),
}

export default ContentLibrary
